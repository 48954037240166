/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable react-native/no-inline-styles */
import { useDebounceFn } from 'ahooks';
import { Button, Col, InputNumber, Pagination, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { setTimeout } from 'timers';
import useAnalysisService from '../analysis.service';

const { Text, Link } = Typography;

export interface USER_F1 {
  page: number | string;
  limit: number | string;
  minNumberUser: number;
  maxNumberUser: number;
  exportExcel?: boolean;
}

function UserIntroduceComponent(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<USER_F1>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    minNumberUser: 1,
    maxNumberUser: 10,
    exportExcel: undefined,
  });

  const { onGetUserF1, dataUserF1, totalUserF1, loadingUserF1 } = useAnalysisService();

  useEffect(() => {
    onGetUserF1(filter);
  }, [filter]);

  const columns = [
    {
      title: 'STT',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Agent Id',
      dataIndex: 'agentId',
      render: (agentId: string) => {
        return <span>{agentId}</span>;
      },
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      render: (name: string, data: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + data?._id} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {name ? (name.length > 30 ? `${name.substring(0, 20)} ...` : name) : ''}
            </Text>
          </Link>
        );
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone: string) => {
        return <span>{phone}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => {
        return <span>{email || ''}</span>;
      },
    },
    {
      title: 'Số CMND/Mã số thuế',
      dataIndex: 'identify',
      render: (identify: string) => {
        return <span>{identify}</span>;
      },
    },
    {
      title: 'Người giới thiệu',
      dataIndex: 'userIntroduceInfo',
      render: (userIntroduceInfo: any) => {
        return <span>{userIntroduceInfo?.name || ''}</span>;
      },
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'customerType',
      render: (customerType: string) => {
        return <span>{customerType === 'PRIVATE' ? 'Cá nhân' : 'Doanh nghiệp'}</span>;
      },
    },
    {
      title: 'Số lượng F1',
      dataIndex: 'totalF1',
      render: (totalF1: any) => {
        return <span>{totalF1?.totalF1 || 0}</span>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === 'ACTIVE' ? 'Hoạt động' : 'Không hoạt động'}</span>;
      },
    },
  ];

  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  const { run } = useDebounceFn(
    (number: number, type: string) => {
      if (type === 'min') {
        setFilter({ ...filter, minNumberUser: number });
      }
      if (type === 'max') {
        setFilter({ ...filter, maxNumberUser: number });
      }
    },
    {
      wait: 500,
    },
  );

  const onClickExcel = () => {
    setFilter({ ...filter, exportExcel: true });
    setTimeout(() => {
      setFilter({ ...filter, exportExcel: undefined });
    }, 1000);
  };

  return (
    <>
      <Row style={{ marginBottom: '17px' }}>
        <Col span={20} style={{ fontSize: '20px' }}>
          <b>Danh sách User giới thiệu thành công từ </b>
          <InputNumber min={1} defaultValue={1} onChange={(value: number) => run(value, 'min')} />
          <b> tới User </b>
          <InputNumber min={1} defaultValue={10} onChange={(value: number) => run(value, 'max')} />
          <b> User </b>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={onClickExcel}>
            Xuất file excel
          </Button>
        </Col>
      </Row>
      <Row>
        {loadingUserF1 ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataUserF1 || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalUserF1}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default UserIntroduceComponent;
