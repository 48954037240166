import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListProjectReq {
  status?: string;
  page?: number;
  limit?: string | number;
  search?: string;
}
export interface ListProjectData {
  total: number;
  data: any;
}

function useLitsProject() {
  const [loading, setLoading] = useState(false);
  const [listProject, setListProject] = useState<ListProjectData>();

  const onListProject = async (request: ListProjectReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<ListProjectReq, any>(BASE_URL.LIST_PROJECT, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        setListProject({ data: res.data, total: res.total });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return { onListProject, listProject, loading };
}

export default useLitsProject;
