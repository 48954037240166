/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Checkbox, Col, Pagination, Row, Table, Typography, Tag } from 'antd';
import { PhoneOutlined, WalletOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import PageLoading from 'components/PageLoading';
import { ToastError, ToastSuccess } from 'components/Toast';
import PaginateList from 'constants/PaginateList';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { CtvSelect } from './components/CtvSelect';
import HeaderCtv from './components/HeaderCtv';
import './ctv.css';
import { BaseStatusCollab, Ctv } from './types/ctv.types';
import useLitsCtv from './utils/ctv-list.hook';
import { updateCtv, quickApproveCollabRequest } from './utils/ctv.service';

export interface Filter {
  status?: string;
  search?: string;
  page: number;
  limit: number;
  projectId?: SelectValue;
}

const { Link, Text } = Typography;

function CtvPage() {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<Filter>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  // Danh sách cộng tác viên
  const { fetchData, dataList = [], loading, setDataList, dataTotal } = useLitsCtv();

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  // Tìm kiếm Tên
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search });
    },
    {
      wait: 500,
    },
  );
  // Select trạng thái
  const onChangeStatus = (status?: BaseStatusCollab) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status });
  };

  // Select dự án
  const onChangeProject = (projectId?: SelectValue) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, projectId });
  };

  // cập nhật trạng thái cộng tác viên
  const onChangeCtvStatus = (_id: string) => async (status: BaseStatusCollab) => {
    if (status === BaseStatusCollab.QUICK_APPROVE) {
      const res = await quickApproveCollabRequest(_id);
      if (res && res.data === true) {
        ToastSuccess({ description: translate('ctv.update_success'), message: translate('ctv.alert') });
        setFilter({ ...filter });
      }
    } else {
      await handleUpdateCtv(_id, 'status', status);
    }
  };

  // Xử lý call api update
  const handleUpdateCtv = async (_id: string, key: string, value: any) => {
    const ctv = [...dataList].find(e => e._id === _id);
    if (ctv) {
      const body = {};
      // @ts-ignore
      body[key] = value;
      const { status, messageKey } = await updateCtv(ctv._id, body);
      if (!status) {
        ToastError({ description: translate(messageKey), message: translate('ctv.alert') });
      } else {
        ToastSuccess({ description: translate('ctv.update_success'), message: translate('ctv.alert') });
        let data: Ctv[] = [...dataList];
        data = data.map((e: Ctv) => {
          if (e._id === _id) {
            // @ts-ignore
            e[key] = value;
          }
          return e;
        });
        setDataList(data);
        setFilter({ ...filter });
      }
    }
  };

  // onChangeVerify
  const onChangeSupporter = (_id: string) => async (event: any & { target: { checked: boolean } }) => {
    await handleUpdateCtv(_id, 'isSupporter', event.target.checked);
  };

  // Action phân trang
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page });
  };

  // Render Column Table
  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: string, item: Ctv, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: translate('ctv.name'),
      key: 'name',
      dataIndex: 'name',
      render: (name: string, data: any) => {
        return [
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + data.userId} target="_blank">
            <Tag color={data.userInfo?.customerType === 'COMPANY' ? '#f50' : '#108ee9'}> {name}</Tag>
          </Link>,
          <p>
            <Tag color="volcano">
              <PhoneOutlined /> {data.phone}
            </Tag>
            <Tag color="cyan">
              <WalletOutlined /> {data.identify}
            </Tag>
          </p>,
        ];
      },
    },
    {
      title: translate('ctv.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email: string) => {
        return <span>{email}</span>;
      },
    },
    {
      title: 'Tên dự án',
      dataIndex: 'project',
      render: (project: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_PROJECT + project?._id} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {project?.name}
            </Text>
          </Link>
        );
      },
    },
    {
      title: translate('ctv.ctv'),
      key: 'isCollaborator',
      dataIndex: 'isCollaborator',
      render: (isCollaborator: boolean) => {
        return <Checkbox checked={isCollaborator} disabled className="ant-checkbox-disabled" />;
      },
      align: 'center' as 'center',
    },
    {
      title: translate('ctv.ctvSupport'),
      key: 'isSupporter',
      dataIndex: 'isSupporter',
      render: (isSupporter: boolean, item: Ctv) => {
        return item.isCollaborator ? (
          <>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_VOLUNTEERS).edit ? (
              <Checkbox
                defaultChecked={
                  item.status === BaseStatusCollab.PENDING_SUPPORTER ||
                  item.status === BaseStatusCollab.APPROVED_SUPPORTER
                    ? true
                    : false
                }
                onChange={onChangeSupporter(item._id)}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <Checkbox checked={isSupporter} disabled />
        );
      },
      align: 'center' as 'center',
    },
    {
      title: translate('ctv.status'),
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      render: (status: BaseStatusCollab, item: Ctv) => {
        return (
          <>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_VOLUNTEERS).edit ? (
              <CtvSelect
                status={status}
                onChangeStatus={onChangeCtvStatus(item._id)}
                showAll={false}
                isFillter={false}
                isCollaborator={item.isCollaborator}
              />
            ) : (
              <CtvSelect
                status={status}
                onChangeStatus={() => {
                  return false;
                }}
                showAll={false}
                isFillter={false}
                isCollaborator={item.isCollaborator}
                disabled={true}
              />
            )}
          </>
        );
      },
      align: 'center' as 'center',
    },
  ];

  return (
    <>
      <HeaderCtv
        onSearch={(value: string) => run(value)}
        onChangeStatus={onChangeStatus}
        onChangeProject={onChangeProject}
      />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            {dataList && (
              <Table
                key={1}
                dataSource={dataList || []}
                columns={renderColumns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
              ></Table>
            )}
          </Col>
        )}
        {dataList && (
          <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div className="d-flex justify-content-end pagination">
              <Pagination
                showSizeChanger={false}
                total={dataTotal}
                current={page}
                defaultCurrent={page}
                defaultPageSize={PaginateList.LIMIT_DEFAULT}
                onChange={onPageChange}
              ></Pagination>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default CtvPage;
