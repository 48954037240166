import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { translate } from 'translate/language';
import { BaseResponse } from 'types/response/BaseResponse';
import { FilterCustomerProject, HouseTransactionsRes, StatusUpdate } from './customer-project.type';
import { MessageViewInput } from './ViewMember.entity';

const LIST_TRANSACTIONS = '/project/transactions';
const DETAIL_TRANSACTION = '/project/detail-transaction';
const DETAIL_TRANSACTION_REAL = '/project-news/transaction/detail';
const UPDATE_STATUS = '/project/transaction';
const UPDATE_VIEW_MESSAGE = '/project/transactions/update-view-message';
const URL = '/project/transaction/history/';

function useCustomerProjectService() {
  const [loading, setLoading] = useState(false);
  const [dataHouseTransactions, setDataHouseTransactions] = useState<HouseTransactionsRes[]>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>();
  const [detailTransaction, setDetailTransaction] = useState<HouseTransactionsRes | undefined>();
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const [dataHistory, setDataHistory] = useState<any>();
  const history = useHistory();

  const onGetHistory = async (id: string) => {
    const res: any = await ApiHelper.fetch(URL + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataHistory(res.data);
    }
  };

  const onGetListTransactions = async (request: FilterCustomerProject) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch<FilterCustomerProject, any>(LIST_TRANSACTIONS, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataHouseTransactions(res.data);
        setTotalTransactions(res.total);
        setLoading(false);
      }
    } catch (error) {
      setDataHouseTransactions([]);
      setLoading(false);
    }
  };

  // Chi tiết khách hàng book căn
  const onDetailTransaction = async (request: { transactionId: string }, type: string) => {
    try {
      const res: any = await ApiHelper.fetch(type === 'REAL' ? DETAIL_TRANSACTION_REAL : DETAIL_TRANSACTION, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailTransaction(res.data);
      }
    } catch (error) {
      setDetailTransaction(undefined);
    }
  };

  // Cập nhật trạng thái
  const updateStatus = async (body: StatusUpdate) => {
    setDisableBtn(true);
    const res: any = await ApiHelper.put(UPDATE_STATUS, body);
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('Thay đổi trạng thái thành công'),
      });
      setDisableBtn(false);
      history.goBack();
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('Thay đổi trạng thái thất bại'),
      });
      setDisableBtn(false);
    }
  };

  const updateViewMessage = async (body: MessageViewInput) => {
    try {
      const res = await ApiHelper.post<any, BaseResponse>(UPDATE_VIEW_MESSAGE, body);
      if (res.status === StatusResponse.SUCCESS) {
        if (res.data._id === detailTransaction!._id) {
          setDetailTransaction({ ...detailTransaction!, areView: res.data.areView });
        }
      }
    } catch (error) {}
  };

  return {
    onGetListTransactions,
    dataHouseTransactions,
    totalTransactions,
    loading,
    updateViewMessage,
    onDetailTransaction,
    detailTransaction,
    updateStatus,
    onGetHistory,
    dataHistory,
    disableBtn,
  };
}

export default useCustomerProjectService;
