import { StatusResponse } from 'constants/Status';

export enum BaseStatusCollab {
  REFUSE = 'REFUSE', //  từ chối
  PENDING = 'PENDING', //  chờ duyệt
  APPROVED = 'APPROVED', //  đã duyệt
  REFUSE_SUPPORTER = 'REFUSE_SUPPORTER', //  từ chối hỗ trợ
  PENDING_SUPPORTER = 'PENDING_SUPPORTER', //  chờ duyệt hỗ trợ
  APPROVED_SUPPORTER = 'APPROVED_SUPPORTER', //  đã duyệt hỗ trợ
  QUICK_APPROVE = 'QUICK_APPROVE',
}

export interface ListCtvReq {
  status?: string;
  page?: number;
  limit?: string | number;
  search?: string;
}
export interface ListCtvRes {
  total: number;
  data: Ctv[];
  status: StatusResponse;
}
export interface Ctv {
  _id: string;
  name: string;
  phone: string;
  status: BaseStatusCollab;
  isCollaborator: boolean;
  isSupporter: boolean;
  identify: string;
  email: string;
  infor: string;
}

export interface UpdateCtvInput {
  isSupporter?: boolean;
  status?: BaseStatusCollab;
}

export interface UpdateCtvRes {
  data: Ctv;
  status: StatusResponse;
}
