/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import { ConstructorType } from 'constants/ConstructorType';
import { BaseStatusProject, StatusType } from 'constants/Status';
import { NAME_UPLOAD, TYPE_DELETE_PROJECT } from 'helpers/Constants';
import { getUrlExport } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import useListAdmin from 'hooks/Admin/useListAdmin';
import useListBank from 'hooks/Bank/useListBank';
import useAddProject from 'hooks/Project/useAddProject';
import useDetailProject from 'hooks/Project/useDetailProject';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import useUpdateProject from 'hooks/Project/useUpdateProject';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import useUploadMultiFile from 'hooks/Upload/useUploadMultiFile';
import useUploadVideo from 'hooks/Upload/useUploadVideo';
import usePhoneCheck from 'hooks/ValidateMatch/usePhoneCheck';
import moment from 'moment';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Option as OptionFilter } from 'react-select/src/filters';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { TYPE_UPLOAD_PROJECT } from '../../helpers/Constants';
import useListConstructor from '../../hooks/Constructor/useListConstructor';
import './css/FormProject.scss';
import InChargeProject from './InChargeProject';
import InputAddLinkVideo from './InputAddLinkVideo';
import UploadButton from './UploadButton';
import UploadButtonImages from './UploadButtonImages';

const { Text, Link } = Typography;
const { Option } = Select;

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function FormProjectPage() {
  const [form] = Form.useForm();

  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const flag = useRef('');
  const { onUploadVideo, resVideo, messageError } = useUploadVideo();
  const { onUploadMultiFile, resUploadMultiFile, messageErrorMulti } = useUploadMultiFile();
  const [onUpload, resUpload] = useUploadMulti();

  const [valueMap, setValueMap] = useState<string | undefined>(undefined);
  const [data, setData] = useState<any[]>([]);
  const [dataLatLng, setDataLatLng] = useState<any>(undefined);

  let timeout: any;
  let currentValue: any;

  // Danh sách thông tin liên hệ
  const [onFetchListAdmin, listAdmin] = useListAdmin();
  useEffect(() => {
    onFetchListAdmin({ status: 'ACTIVE' });
  }, []);

  // UPLOAD Hình ảnh quy mô dự án
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');

  // Giá trị hình ảnh
  const [fileListScale, setFileListScale] = useState<UploadFile<any>[] | any>([]);
  const [fileListLocationDetail, setFileListLocationDetail] = useState<UploadFile<any>[] | any>([]);
  const [fileListOverview, setFileListOverview] = useState<UploadFile<any>[] | any>([]);

  const [fileListUtility, setFileListUtility] = useState<UploadFile<any>[] | any>([]);
  const [fileListfloorPlan, setFileListfloorPlan] = useState<UploadFile<any>[] | any>([]);
  const [fileListpaymentMethod, setFileListpaymentMethod] = useState<UploadFile<any>[] | any>([]);

  const [fileListPolicySale, setFileListPolicySale] = useState<UploadFile<any>[] | any>([]);
  const [fileListPolicyBank, setFileListPolicyBank] = useState<UploadFile<any>[] | any>([]);

  // Tài liệu đính kèm
  const [fileListProjectMediaIds, setFileListProjectMediaIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListLocationMediaIds, setFileListLocationMediaIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListOverviewMediaIds, setFileListOverviewMediaIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListAreaMediaIds, setFileListAreaMediaIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListOrtherMediaIds, setFileListOrtherMediaIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListAllIds, setFileListAllIds] = useState<UploadFile<any>[] | any>([]);

  // Video
  const [fileListVideoIds, setfileListVideoIds] = useState<UploadFile<any>[] | any>([]);
  const [fileListFiles, setfileListFiles] = useState<UploadFile<any>[] | any>([]);

  // Hình ảnh quy mô dự án
  const handleChangeScale = async (fileList: any) => {
    const newData = [...fileListScale, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = 'uploadImagefileListScaleIds';
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListScale(newData);
  };

  // Hình ảnh vị trí dự án
  const handleChangeLocationDetail = async (fileList: any) => {
    const newData = [...fileListLocationDetail, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = 'uploadImagefileListLocationDetailIds';
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListLocationDetail(newData);
  };

  // Hình ảnh tổng quan dự án
  const handleChangeOverview = async (fileList: any) => {
    const newData = [...fileListOverview, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImagefileListOverviewIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListOverview(newData);
  };
  // hình ảnh file tiện tihcs
  const handleChangeUtility = async (fileList: any) => {
    const newData = [...fileListUtility, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageUtility;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListUtility(newData);
  };
  // hình ảnh file mawjt bang
  const handleChangeFloorPlan = async (fileList: any) => {
    const newData = [...fileListfloorPlan, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImagefloorPlan;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListfloorPlan(newData);
  };
  // hình ảnh file phương thức thanh toán
  const handleChangeMethodPay = async (fileList: any) => {
    const newData = [...fileListpaymentMethod, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImagePaymentMethod;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListpaymentMethod(newData);
  };

  // Hình ảnh chính sách hỗ trợ bán hàng
  const handleChangePolicySale = async (fileList: any) => {
    const newData = [...fileListPolicySale, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImagePolicySaleIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListPolicySale(newData);
  };

  // Hình ảnh chính sách hỗ trợ bán hàng
  const handleChangePolicyBank = async (fileList: any) => {
    const newData = [...fileListPolicyBank, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImagePolicyBankIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListPolicyBank(newData);
  };

  // Tài liệu đính kèm
  const handleChangeProjectMediaIds = async (fileList: any) => {
    const newData = [...fileListProjectMediaIds, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageProjectIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListProjectMediaIds(newData);
  };
  const handleChangeLocationMediaIds = async (fileList: any) => {
    const newData = [...fileListLocationMediaIds, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageLocationIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListLocationMediaIds(newData);
  };

  /// ảnh tổng quan dự án------------------------
  const handleChangeOverviewMediaIds = async (fileList: any) => {
    const newData = [...fileListOverviewMediaIds, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageOverviewIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListOverviewMediaIds(newData);
  };

  /// upload Ảnh đất, mặt bằng ------------------------
  const handleChangeAreaMediaIds = async (fileList: any) => {
    const newData = [...fileListAreaMediaIds, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageAreaIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListAreaMediaIds(newData);
  };

  /// upload Khac ------------------------
  const handleChangeOrtherMediaIds = async (fileList: any) => {
    const newData = [...fileListOrtherMediaIds, ...fileList.currentTarget.files];
    const data = new FormData();
    flag.current = TYPE_UPLOAD_PROJECT.uploadImageOrtherIds;
    // check type image
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    onUpload(data, BASE_URL.UPLOAD_MULTI_FILE_10MB);
    setFileListOrtherMediaIds(newData);
  };

  // upload ảnh multiple
  useEffect(() => {
    switch (flag.current) {
      case TYPE_UPLOAD_PROJECT.uploadImagefileListScaleIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListScaleDetail = [...fileListScale].filter(e => e._id);
          const newfileListScaleListIds = [...newfileListScaleDetail, ...resUpload];
          setFileListScale(newfileListScaleListIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagefileListLocationDetailIds:
        if (resUpload && resUpload.length > 0) {
          const newfilefileListLocationDetail = [...fileListLocationDetail].filter(e => e._id);
          const newfileListLocationDetailListIds = [...newfilefileListLocationDetail, ...resUpload];
          setFileListLocationDetail(newfileListLocationDetailListIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagefileListOverviewIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListOverview = [...fileListOverview].filter(e => e._id);
          const newOverviewListIds = [...newfileListOverview, ...resUpload];
          setFileListOverview(newOverviewListIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagePolicySaleIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListPolicySaleMediaIds = [...fileListPolicySale].filter(e => e._id);
          const newPolicySaleIds = [...newfileListPolicySaleMediaIds, ...resUpload];
          setFileListPolicySale(newPolicySaleIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagePolicyBankIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListPolicyMediaIds = [...fileListPolicyBank].filter(e => e._id);
          const newPolicyIds = [...newfileListPolicyMediaIds, ...resUpload];
          setFileListPolicyBank(newPolicyIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageOverviewIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListOverviewMediaIds = [...fileListOverviewMediaIds].filter(e => e._id);
          const newOverViewIds = [...newfileListOverviewMediaIds, ...resUpload];
          setFileListOverviewMediaIds(newOverViewIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageAreaIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListAreaMediaIds = [...fileListAreaMediaIds].filter(e => e._id);
          const newAreaIds = [...newfileListAreaMediaIds, ...resUpload];
          setFileListAreaMediaIds(newAreaIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageOrtherIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListOrtherMediaIds = [...fileListOrtherMediaIds].filter(e => e._id);
          const newAreaIds = [...newfileListOrtherMediaIds, ...resUpload];
          setFileListOrtherMediaIds(newAreaIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageLocationIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListLocationMediaIds = [...fileListLocationMediaIds].filter(e => e._id);
          const newLocationIds = [...newfileListLocationMediaIds, ...resUpload];
          setFileListLocationMediaIds(newLocationIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageProjectIds:
        if (resUpload && resUpload.length > 0) {
          const newfileListProjectMediaIds = [...fileListProjectMediaIds].filter(e => e._id);
          const newProjectIds = [...newfileListProjectMediaIds, ...resUpload];
          setFileListProjectMediaIds(newProjectIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImageUtility:
        if (resUpload && resUpload.length > 0) {
          const newfileListUtilityMediaIds = [...fileListUtility].filter(e => e._id);
          const newProjectIds = [...newfileListUtilityMediaIds, ...resUpload];
          setFileListUtility(newProjectIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagePaymentMethod:
        if (resUpload && resUpload.length > 0) {
          const newfileListpaymentMethodMediaIds = [...fileListpaymentMethod].filter(e => e._id);
          const newProjectIds = [...newfileListpaymentMethodMediaIds, ...resUpload];
          setFileListpaymentMethod(newProjectIds);
        }
        break;
      case TYPE_UPLOAD_PROJECT.uploadImagefloorPlan:
        if (resUpload && resUpload.length > 0) {
          const newfileListFloorPlanMediaIds = [...fileListfloorPlan].filter(e => e._id);
          const newProjectIds = [...newfileListFloorPlanMediaIds, ...resUpload];
          setFileListfloorPlan(newProjectIds);
        }
        break;

      default:
        break;
    }
  }, [resUpload]);
  // upload ảnh multiple

  // Upload video ------------------------------ VIdeo
  useEffect(() => {
    if (flag.current === 'uploadVideoIds') {
      let imageVideoIds: any[] = [];
      if (resVideo && resVideo._id) {
        if (fileListVideoIds && fileListVideoIds.length > 0) {
          const newVIdeoIds = fileListVideoIds.map((e: any) => {
            if (!e._id) {
              return (e = { ...e, ...resVideo });
            }
            return e;
          });
          setfileListVideoIds(newVIdeoIds);
        }
        imageVideoIds = [...dataVideoIds, resVideo._id];
        if (imageVideoIds?.length > 0) {
          setDataVideoIds(imageVideoIds);
        }
      }
    }
  }, [resVideo]);
  // Upload video ------------------------------ VIdeo

  /// select video
  const onChangeInputFileVideo = (value: any) => {
    const newData = [value, ...fileListVideoIds];
    setfileListVideoIds(newData);
  };

  // remove item video
  const onRemoveItem = (item: any) => {
    confirm({
      title: translate('delete_video'),
      onOk: () => {
        const newFileVideo = [...fileListVideoIds].filter(e => e !== item);
        setfileListVideoIds(newFileVideo);
      },
      onCancel: () => {},
    });
  };
  // remove item  area
  const onRemoveItemArea = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: translate('image') }),
      onOk: () => {
        const newFileArea = [...fileListAreaMediaIds].filter(e => e._id !== item._id);
        setFileListAreaMediaIds(newFileArea);
      },
      onCancel: () => {},
    });
  };

  // remove ảnh khác
  const onRemoveItemOrther = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: translate('image') }),
      onOk: () => {
        const newFileArea = [...fileListOrtherMediaIds].filter(e => e._id !== item._id);
        setFileListOrtherMediaIds(newFileArea);
      },
      onCancel: () => {},
    });
  };

  // remove item  overview
  const onRemoveItemOverView = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: translate('image') }),
      onOk: () => {
        const newFileOverView = [...fileListOverviewMediaIds].filter(e => e._id !== item._id);
        setFileListOverviewMediaIds(newFileOverView);
      },
      onCancel: () => {},
    });
  };
  // remove item  overview
  const onRemoveItemLocation = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: translate('image') }),
      onOk: () => {
        const newFileLocation = [...fileListLocationMediaIds].filter(e => e._id !== item._id);
        setFileListLocationMediaIds(newFileLocation);
      },
      onCancel: () => {},
    });
  };
  // remove item  project
  const onRemoveItemProject = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: translate('image') }),
      onOk: () => {
        const newFileProject = [...fileListProjectMediaIds].filter(e => e._id !== item._id);
        setFileListProjectMediaIds(newFileProject);
      },
      onCancel: () => {},
    });
  };
  // Upload video ------------------------------ VIdeo

  // Tài liệu đính kèm ------------------------------- đính kèm
  useEffect(() => {
    // Upload video
    if (flag.current === 'upLoadFileIds') {
      let fileIds: any[] = [];
      if (resUploadMultiFile && resUploadMultiFile.length > 0) {
        const newfileListFiles = [...fileListFiles].filter(e => e._id);
        const newVIdeoIds = [...resUploadMultiFile, ...newfileListFiles];
        setfileListFiles(newVIdeoIds);
        resUploadMultiFile.forEach((element: any) => {
          fileIds = [...dataVideoIds, element._id];
        });
        if (fileIds.length > 0) {
          setFileListAllIds(fileIds);
        }
      }
    }
  }, [resUploadMultiFile]);

  const handleChangeFiles = (fileList: any) => {
    [...fileList.currentTarget.files].map((data: any, index: number) => {
      if (
        data.type !== 'application/msword' ||
        data.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        data.type !== 'application/pdf' ||
        data.type !== 'application/vnd.ms-excel' ||
        data.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
      }
    });

    const newData = [...fileList.currentTarget.files, ...fileListFiles];
    flag.current = 'upLoadFileIds';
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      if (
        e.type === 'application/msword' ||
        e.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        e.type === 'application/pdf' ||
        e.type === 'application/vnd.ms-excel' ||
        e.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        data.append('files', e);
      } else {
        return message.warning(`File không đúng định dạng`);
      }
    }
    onUploadMultiFile(data);
    setfileListFiles(newData);
  };

  // xoá file
  const onRemoveItemFile = (item: any) => {
    confirm({
      title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
      onOk: () => {
        const newFileVideo = [...fileListFiles].filter(e => e._id !== item._id);
        const NewimageVideoIds = [...fileListAllIds].filter(e => e !== item._id);
        setFileListAllIds(NewimageVideoIds);
        setfileListFiles(newFileVideo);
      },
      onCancel: () => {},
    });
  };
  // Tài liệu đính kèm ------------------------------- đính kèm

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj || file);
    }
    setPreviewImage(getUrlExport(file.url) || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  // Lấy giá trị ID upload => Gửi lên server
  // - Upload video
  const [dataVideoIds, setDataVideoIds] = useState<any[]>([]);

  const dateFormatBirthday = ['DD/MM/YYYY'];

  // Thêm mới dự án
  const { onCreateProject, loadingProject } = useAddProject();

  // Loại bất động sản
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();

  // Chủ đầu tư
  const [onFetchListConstructor, listConstructor, loading] = useListConstructor();

  // Danh sách ngân hàng
  const [onFetchListBank, listBank, loadingBank] = useListBank();

  // Get danh sách số điện thoại
  const { onGetPhoneCode, listPhone, loadingPhone } = usePhoneCheck();

  // ID người giới thiệu
  const [idInCharge, setIdInCharge] = useState<string>('');

  // Chi tiết dự án
  const { onDetailProject, detailProject } = useDetailProject();
  // Cập nhập dự án
  const { onUpdateProject, loadingUpdateProject } = useUpdateProject();

  // Chủ đầu tư
  const [dataInvestor, setDataInvestor] = useState<any>([]);
  // Đơn vị phát triển
  const [dataDevelop, setdataDevelop] = useState<any>([]);
  // Tư vấn thiết kế
  const [dataDesign, setDataDesign] = useState<any>([]);
  // Nhà thầu xây dựng chính
  const [dataContractor, setDataContractor] = useState<any>([]);
  // Tiếp thị phân phối
  const [dataMarketing, setDataMarketing] = useState<any>([]);
  // Ban quản lý vận hành
  const [dataOperator, setDataOperator] = useState<any>([]);

  useEffect(() => {
    onFetchListConstructor({
      status: StatusType.ACTIVE,
    });
    onFetchListBank({
      status: StatusType.ACTIVE,
    });
    onGetPhoneCode();

    onFetchListParentChildRealeState();
  }, []);

  // Set giá trị vào from
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_PROJECT);

  // Khi sửa dự án
  useEffect(() => {
    if (isEdit && detailProject) {
      setDataInvestor(detailProject.investor);
      setdataDevelop(detailProject.developer);
      setDataDesign(detailProject.design);
      setDataContractor(detailProject.contractor);
      setDataMarketing(detailProject.marketing);
      setDataOperator(detailProject.operator);

      form.setFieldsValue({
        isFeatured: detailProject.isFeatured ? 'true' : 'false',
        status: detailProject.status,
        project_name: detailProject.name,
        realEstate: detailProject.realEstate,
        location: detailProject.location,
        areaResearch: detailProject.areaResearch,
        areaConstruct: detailProject.areaConstruct,
        density: detailProject.density,
        // reservationFee: detailProject.reservationFee,
        investor: detailProject.investor,
        contractor: detailProject.contractor,
        design: detailProject.design,
        developer: detailProject.developer,
        marketing: detailProject.marketing,
        operator: detailProject.operator,
        banks: detailProject.banks,
        // Sản phẩm
        blocks: detailProject?.products?.apartments?.blocks,
        floors: detailProject?.products?.apartments?.floors,
        apartments: detailProject?.products?.apartments?.apartments,
        reservationFee: detailProject?.products?.apartments?.reservationFee,
        depositFee: detailProject?.products?.apartments?.depositFee,
        commission: detailProject?.products?.apartments?.commission,

        divisions: detailProject?.products?.villa?.divisions,
        houses: detailProject?.products?.villa?.houses,
        floors_h: detailProject?.products?.villa?.floors,
        reservationFee_1: detailProject?.products?.villa?.reservationFee,
        depositFee_1: detailProject?.products?.villa?.depositFee,
        commission_1: detailProject?.products?.villa?.commission,

        shophouse_divisions: detailProject?.products?.shophouse?.divisions,
        shophouse_houses: detailProject?.products?.shophouse?.houses,
        shophouse_floors: detailProject?.products?.shophouse?.floors,
        reservationFee_2: detailProject?.products?.shophouse?.reservationFee,
        depositFee_2: detailProject?.products?.shophouse?.depositFee,
        commission_2: detailProject?.products?.shophouse?.commission,

        land_divisions: detailProject?.products?.land?.divisions,
        lots: detailProject?.products?.land?.lots,
        minArea: detailProject?.products?.land?.minArea,
        maxArea: detailProject?.products?.land?.maxArea,
        reservationFee_3: detailProject?.products?.land?.reservationFee,
        depositFee_3: detailProject?.products?.land?.depositFee,
        commission_3: detailProject?.products?.land?.commission,

        // Văn bản
        scale: detailProject.details?.scale ? detailProject.details?.scale?.text || '' : '',
        location_detail: detailProject.details?.location ? detailProject.details?.location?.text || '' : '',
        overview: detailProject.details?.overview ? detailProject.details?.overview?.text || '' : '',
        policyBank: detailProject.details?.policyBank ? detailProject.details?.policyBank?.text || '' : '',
        policySale: detailProject.details?.policySale ? detailProject.details?.policySale?.text || '' : '',
        floorPlan: detailProject.details?.floorPlan ? detailProject.details?.floorPlan?.text || '' : '',
        utility: detailProject.details?.utility ? detailProject.details?.utility?.text || '' : '',
        paymentMethod: detailProject.details?.paymentMethod ? detailProject.details?.paymentMethod?.text || '' : '',

        contacts: detailProject.details?.contacts ? detailProject.details?.contacts.map((data: any) => data._id) : [],
        launchDate: detailProject.launchDate ? moment(detailProject.launchDate) : null,
        inCharge: detailProject.details?.inCharge?._id,
        // Cấu hình
        maxHoldTime: detailProject?.configs.maxHoldTime,
        maxVolunteersSupportTime: detailProject?.configs.maxVolunteersSupportTime,
      });

      // Vị trí dự án
      setValueMap(detailProject?.location?.addressName);
      setDataLatLng({
        lat: detailProject.lat || 0,
        lng: detailProject.lon || 0,
      });

      // Set dữ liệu vào table thông tin liên hệ
      if (detailProject.details?.contacts) {
        setDataContact(detailProject.details?.contacts);
      }

      setFileListScale(
        (detailProject &&
          detailProject.details &&
          detailProject.details.scale &&
          detailProject.details.scale.mediaIds) ||
          [],
      );
      setFileListLocationDetail(
        (detailProject &&
          detailProject.details &&
          detailProject.details.location &&
          detailProject.details.location.mediaIds) ||
          [],
      );
      setFileListOverview(
        (detailProject &&
          detailProject.details &&
          detailProject.details.overview &&
          detailProject.details.overview.mediaIds) ||
          [],
      );
      setFileListPolicySale(
        (detailProject &&
          detailProject.details &&
          detailProject.details.policySale &&
          detailProject.details.policySale.mediaIds) ||
          [],
      );
      setFileListPolicyBank(
        (detailProject &&
          detailProject.details &&
          detailProject.details.policyBank &&
          detailProject.details.policyBank.mediaIds) ||
          [],
      );

      setFileListUtility(
        (detailProject &&
          detailProject.details &&
          detailProject.details.utility &&
          detailProject.details.utility.mediaIds) ||
          [],
      );
      setFileListpaymentMethod(
        (detailProject &&
          detailProject.details &&
          detailProject.details.paymentMethod &&
          detailProject.details.paymentMethod.mediaIds) ||
          [],
      );
      setFileListfloorPlan(
        (detailProject &&
          detailProject.details &&
          detailProject.details.floorPlan &&
          detailProject.details.floorPlan.mediaIds) ||
          [],
      );

      setfileListFiles(detailProject.filesIncluded.files);
      setfileListVideoIds(detailProject.filesIncluded.videos);
      setFileListOverviewMediaIds(detailProject.filesIncluded.overviewMediaIds);
      setFileListAreaMediaIds(detailProject.filesIncluded.areaMediaIds);
      setFileListOrtherMediaIds(detailProject.filesIncluded.otherPhotos);
      setFileListLocationMediaIds(detailProject.filesIncluded.locationMediaIds);
      setFileListProjectMediaIds(detailProject.filesIncluded.projectMediaIds.filter((e: any) => e));
      // Set ID người giới thiệu
      setIdInCharge(detailProject.details?.inCharge?._id);

      // Set dữ liệu vào CKEditor
      setScaleContent(detailProject.details?.scale ? detailProject.details?.scale?.text || '' : '');
      setLocationContent(detailProject.details?.location ? detailProject.details?.location?.text || '' : '');
      setOverviewContent(detailProject.details?.overview ? detailProject.details?.overview?.text || '' : '');
      setPolicySaleContent(detailProject.details?.policySale ? detailProject.details?.policySale?.text || '' : '');
      setPolicyBankContent(detailProject.details?.policyBank ? detailProject.details?.policyBank?.text || '' : '');

      setutilityContent(detailProject.details?.utility ? detailProject.details?.utility?.text || '' : '');
      setPaymentMethodContent(
        detailProject.details?.paymentMethod ? detailProject.details?.paymentMethod?.text || '' : '',
      );
      setfloorPlanContent(detailProject.details?.floorPlan ? detailProject.details?.floorPlan?.text || '' : '');
    }
  }, [detailProject]);

  useEffect(() => {
    if (isEdit) {
      onDetailProject(params?.id);
    } else {
      form.setFieldsValue({
        apartments: cacheForm.apartments,
        areaConstruct: cacheForm.areaConstruct,
        areaResearch: cacheForm.areaResearch,
        banks: cacheForm.banks,
        blocks: cacheForm.blocks,
        contractor: cacheForm.contractor,
        density: cacheForm.density,
        design: cacheForm.design,
        developer: cacheForm.developer,
        divisions: cacheForm.divisions,
        floors: cacheForm.floors,
        floors_h: cacheForm.floors_h,
        houses: cacheForm.houses,
        // reservationFee: cacheForm.reservationFee,

        // investor: cacheForm.investor,
        // isFeatured: cacheForm.isFeatured,
        land_divisions: cacheForm.land_divisions,
        launchDate: cacheForm.launchDate ? moment(cacheForm.launchDate) : moment(new Date()),
        // location: cacheForm.location,
        location_detail: cacheForm.location_detail,
        lots: cacheForm.lots,
        marketing: cacheForm.marketing,
        maxArea: cacheForm.maxArea,
        maxHoldTime: cacheForm.maxHoldTime,
        maxVolunteersSupportTime: cacheForm.maxVolunteersSupportTime,
        minArea: cacheForm.minArea,
        operator: cacheForm.operator,
        overview: cacheForm.overview,
        policyBank: cacheForm.policyBank,
        policySale: cacheForm.policySale,
        project_name: cacheForm.project_name,
        realEstate: cacheForm.realEstate,
        scale: cacheForm.scale,
        shophouse_divisions: cacheForm.shophouse_divisions,
        shophouse_floors: cacheForm.shophouse_floors,
        shophouse_houses: cacheForm.shophouse_houses,
        // status: cacheForm.status,
      });
    }
  }, []);

  const onRemoveItemAllProject = (item: any, index: any, type: string) => {
    switch (type) {
      case TYPE_DELETE_PROJECT.fileListScale:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListScaleIds = [...fileListScale].filter(e => e._id !== item._id);
            setFileListScale(NewListScaleIds);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListLocationDetail:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListLocationDetailIds = [...fileListLocationDetail].filter(e => e._id !== item._id);
            setFileListLocationDetail(NewListLocationDetailIds);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListOverview:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListOverviewIds = [...fileListOverview].filter(e => e._id !== item._id);
            setFileListOverview(NewListfileListOverviewIds);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListPolicySale:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListPolicySaleIds = [...fileListPolicySale].filter(e => e._id !== item._id);
            setFileListPolicySale(NewListfileListPolicySaleIds);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListPolicyBank:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListPolicyBankIds = [...fileListPolicyBank].filter(e => e._id !== item._id);
            setFileListPolicyBank(NewListfileListPolicyBankIds);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListUtility:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListUtility = [...fileListUtility].filter(e => e._id !== item._id);
            setFileListUtility(NewListfileListUtility);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListpaymentMethod:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListpaymentMethod = [...fileListpaymentMethod].filter(e => e._id !== item._id);
            setFileListpaymentMethod(NewListfileListpaymentMethod);
          },
          onCancel: () => {},
        });
        break;
      case TYPE_DELETE_PROJECT.fileListfloorPlan:
        confirm({
          title: translate('delete_custome_all_file', { name: NAME_UPLOAD[item.type] }),
          onOk: () => {
            const NewListfileListfloorPlan = [...fileListfloorPlan].filter(e => e._id !== item._id);
            setFileListfloorPlan(NewListfileListfloorPlan);
          },
          onCancel: () => {},
        });
        break;

      default:
        break;
    }
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_PROJECT, form.getFieldsValue());
  };

  const handSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields().then(values => {
      const newDataProject = {
        name: values.project_name,
        isFeatured: values.isFeatured === 'true' ? true : false,
        status: values.status,
        realEstate: values.realEstate,
        location: valueMap || '',
        lat: (dataLatLng && dataLatLng.lat) || 0,
        lon: (dataLatLng && dataLatLng.lng) || 0,
        areaResearch: values.areaResearch,
        areaConstruct: values.areaConstruct,
        // reservationFee: values.reservationFee,
        density: values.density,
        investor: dataInvestor, // Chủ đầu tư
        design: dataDesign, // Tư vấn thiết kế
        developer: dataDevelop, // Đơn vị phát triển
        contractor: dataContractor, // Nhà thầu xây dựng
        marketing: dataMarketing, // Tiếp thị phân phối
        operator: dataOperator,
        banks: values.banks ? values.banks : [],
        launchDate: moment(values.launchDate).format('YYYY/MM/DD'),
        products: {
          apartments: {
            blocks: values?.blocks,
            floors: values?.floors,
            apartments: values?.apartments,
            reservationFee: values.reservationFee || 0,
            depositFee: values.depositFee || 0,
            commission: values.commission || 0,
          },
          villa: {
            divisions: values?.divisions,
            houses: values?.houses,
            floors: values?.floors_h,
            reservationFee: values.reservationFee_1 || 0,
            depositFee: values.depositFee_1 || 0,
            commission: values.commission_1 || 0,
          },
          shophouse: {
            divisions: values?.shophouse_divisions,
            houses: values?.shophouse_houses,
            floors: values?.shophouse_floors,
            reservationFee: values.reservationFee_2 || 0,
            depositFee: values.depositFee_2 || 0,
            commission: values.commission_2 || 0,
          },
          land: {
            divisions: values?.land_divisions,
            lots: values?.lots,
            minArea: values?.minArea,
            maxArea: values?.maxArea,
            reservationFee: values.reservationFee_3 || 0,
            depositFee: values.depositFee_3 || 0,
            commission: values.commission_3 || 0,
          },
        },
        details: {
          scale: {
            text: values.scale || '',
            mediaIds: fileListScale.map((x: any) => x._id) || [],
          },
          location: {
            text: values.location_detail || '',
            mediaIds: fileListLocationDetail.map((x: any) => x._id) || [],
          },
          overview: {
            text: values.overview || '',
            mediaIds: fileListOverview.map((x: any) => x._id) || [],
          },
          policyBank: {
            text: values.policyBank || '',
            mediaIds: fileListPolicyBank.map((x: any) => x._id) || [],
          },
          policySale: {
            text: values.policySale || '',
            mediaIds: fileListPolicySale.map((x: any) => x._id) || [],
          },
          floorPlan: {
            text: values.floorPlan || '',
            mediaIds: fileListfloorPlan.map((x: any) => x._id) || [],
          },
          utility: {
            text: values.utility || '',
            mediaIds: fileListUtility.map((x: any) => x._id) || [],
          },
          paymentMethod: {
            text: values.paymentMethod || '',
            mediaIds: fileListpaymentMethod.map((x: any) => x._id) || [],
          },
          contacts: values.contacts ? values.contacts : [],
          inCharge: values?.inCharge,
        },
        filesIncluded: {
          projectMediaIds: (fileListProjectMediaIds && fileListProjectMediaIds.map((x: any) => x._id)) || [],
          locationMediaIds: (fileListLocationMediaIds && fileListLocationMediaIds.map((x: any) => x._id)) || [],
          overviewMediaIds: (fileListOverviewMediaIds && fileListOverviewMediaIds.map((x: any) => x._id)) || [],
          areaMediaIds: (fileListAreaMediaIds && fileListAreaMediaIds.map((x: any) => x._id)) || [],
          otherPhotos: (fileListOrtherMediaIds && fileListOrtherMediaIds.map((x: any) => x._id)) || [],
          videos: (fileListVideoIds && fileListVideoIds.map((x: any) => x)) || [],
          files: (fileListFiles && fileListFiles.map((x: any) => x._id)) || [],
        },
        configs: {
          maxHoldTime: values.maxHoldTime,
          maxVolunteersSupportTime: values.maxVolunteersSupportTime,
        },
      };

      // Thêm + sửa dự án
      if (isEdit && detailProject) {
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PROJECT);
        return onUpdateProject(params.id, newDataProject);
      } else {
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PROJECT);
        onCreateProject(newDataProject);
      }
    });
  };
  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PROJECT);
    history.back();
  };

  const onChangeInvestor = (value: any, option: OptionFilter | Array<OptionFilter> | any, type: string) => {
    let setType = '';
    if (type === 'investor') {
      setType = ConstructorType.INVESTOR;
    }
    if (type === 'developer') {
      setType = ConstructorType.DEVELOPER;
    }
    if (type === 'design') {
      setType = ConstructorType.DESIGN;
    }
    if (type === 'contractor') {
      setType = ConstructorType.CONTRACTOR;
    }
    if (type === 'marketing') {
      setType = ConstructorType.MARKETING;
    }
    if (type === 'operator') {
      setType = ConstructorType.OPERATOR;
    }
    const dataCreate: any[] = [];
    option.map((data: any, index: number) => {
      if (data.key) {
        dataCreate.push(value[index]);
      } else {
        dataCreate.push({
          name: value[index],
          type: setType,
        });
      }
    });
    if (type === 'investor') {
      setDataInvestor(dataCreate);
    }
    if (type === 'developer') {
      setdataDevelop(dataCreate);
    }
    if (type === 'design') {
      setDataDesign(dataCreate);
    }
    if (type === 'contractor') {
      setDataContractor(dataCreate);
    }
    if (type === 'marketing') {
      setDataMarketing(dataCreate);
    }
    if (type === 'operator') {
      setDataOperator(dataCreate);
    }
  };

  const [idContact, setIdContact] = useState<string[]>([]);
  const [dataContact, setDataContact] = useState<any[]>([]);

  const columnsContact = [
    {
      title: 'Họ tên',
      render: (data: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_ADMIN + data._id} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {data.name}
            </Text>
          </Link>
        );
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone: number) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => {
        return <Text>{email}</Text>;
      },
    },
  ];

  // onChange Thông tin liên hệ
  const onHandChangeContact = (value: any) => {
    setIdContact(value);
  };

  useEffect(() => {
    if (listAdmin && listAdmin.data) {
      const dataMap = idContact.map((data: string) => {
        const temp = listAdmin.data.find((e: any) => e._id === data);
        return temp;
      });
      setDataContact(dataMap);
    }
  }, [idContact]);

  // CKEditor

  // Quy mô dự án
  const [scaleContent, setScaleContent] = useState<string>('');
  const handleChangeContentScale = (event: any, editor: any) => {
    form.setFieldsValue({
      scale: editor.getData(),
    });
  };

  // Vị trí dự án
  const [locationContent, setLocationContent] = useState<string>('');
  const handleContentLocationDetail = (event: any, editor: any) => {
    form.setFieldsValue({
      location_detail: editor.getData(),
    });
  };

  // Tổng quan dự án
  const [overviewContent, setOverviewContent] = useState<string>('');
  const handleContentOverview = (event: any, editor: any) => {
    form.setFieldsValue({
      overview: editor.getData(),
    });
  };

  // tiện tích
  const [utilityContent, setutilityContent] = useState<string>('');
  const handleContentutility = (event: any, editor: any) => {
    form.setFieldsValue({
      utility: editor.getData(),
    });
  };

  // phương thức thanh tonas
  const [payMentMethodContent, setPaymentMethodContent] = useState<string>('');
  const handleContentPaymentMethod = (event: any, editor: any) => {
    form.setFieldsValue({
      paymentMethod: editor.getData(),
    });
  };

  // mặt bằng tầng
  const [floorPlanContent, setfloorPlanContent] = useState<string>('');
  const handleContentfloorPlan = (event: any, editor: any) => {
    form.setFieldsValue({
      floorPlan: editor.getData(),
    });
  };

  // Chính sách hỗ trợ bán hàng
  const [policySaleContent, setPolicySaleContent] = useState<string>('');
  const handleContentPolicySale = (event: any, editor: any) => {
    form.setFieldsValue({
      policySale: editor.getData(),
    });
  };

  // Chính sách ngân hàng hỗ trợ
  const [policyBankContent, setPolicyBankContent] = useState<string>('');
  const handleContentPolicyBank = (event: any, editor: any) => {
    form.setFieldsValue({
      policyBank: editor.getData(),
    });
  };

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingSkeleton(false);
    }, 1000);
  }, []);

  const fetchData = (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    function fetchPlaces() {
      ApiHelper.fetch('project-news/googlePlace', {
        search: value,
      })
        .then((response: any) => {
          return response.data?.googleData;
        })
        .then(predictions => {
          if (predictions && currentValue === value) {
            if (predictions.length > 0) {
              const dataSelect = predictions.map((data: any) => {
                return {
                  value: data.address,
                  text: data.address,
                  lat: data.location.lat || 0,
                  lng: data.location.lng || 0,
                };
              });
              callback(dataSelect);
            } else {
              callback([]);
            }
          }
        });
    }

    timeout = setTimeout(fetchPlaces, 500);
  };

  const handleSearchMap = (value: any) => {
    if (value) {
      fetchData(value, (data: any) => {
        setData(data);
      });
    } else {
      setData([]);
    }
  };

  const handleChangeMap = (value: any, option: any) => {
    setValueMap(value);
    if (option && option.data) {
      setDataLatLng(option.data);
    }
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS).edit ? (
        <Skeleton loading={loadingSkeleton} active>
          <>
            <Modal visible={previewVisible} title="" footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Form form={form} onFieldsChange={updateCacheForm}>
              <Row>
                <Col span={23} offset={2}>
                  <Row gutter={20} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={3}></Col>
                    <Col span={8} style={{ paddingLeft: '0px' }}>
                      <div style={{ textAlign: 'center' }} className="highlight">
                        {translate('project.highlight')}
                      </div>
                      <div>
                        <Form.Item
                          name="isFeatured"
                          initialValue="false"
                          rules={[
                            {
                              required: true,
                              message: translate('require_field', {
                                label: translate('project.highlight'),
                              }),
                            },
                          ]}
                        >
                          <Select>
                            <Option value="false">{translate('project.no')}</Option>
                            <Option value="true">{translate('project.yes')}</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div style={{ textAlign: 'center' }} className="highlight">
                        {translate('status.name')}
                      </div>
                      <div>
                        <Form.Item
                          name="status"
                          initialValue={BaseStatusProject.INACTIVE}
                          rules={[
                            {
                              required: true,
                              message: translate('require_field', {
                                label: translate('status.name'),
                              }),
                            },
                          ]}
                        >
                          <Select placeholder={translate('status.name')}>
                            <Option value={BaseStatusProject.INACTIVE}>
                              {translate('BaseStatusProject.INACTIVE')}
                            </Option>
                            <Option value={BaseStatusProject.FOR_SALE}>
                              {translate('BaseStatusProject.FOR_SALE')}
                            </Option>
                            <Option value={BaseStatusProject.NOT_FOR_SALE}>
                              {translate('BaseStatusProject.NOT_FOR_SALE')}
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="project_name"
                        label={translate('project.name')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            whitespace: true,
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.name'),
                            }),
                          },
                          {
                            transform: (value: string) => {
                              return value?.trim();
                            },
                            min: 2,
                            max: 200,
                            message: translate('project.name_invalid'),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="realEstate"
                        label={translate('project.realEstate')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.realEstate'),
                            }),
                            type: 'array',
                          },
                        ]}
                      >
                        <Select placeholder={translate('project.realEstate')} mode="multiple">
                          {ListParentChildRealeState
                            ? ListParentChildRealeState.map((data: any, key: number) => {
                                return (
                                  <Option value={data._id} key={data._id} title={data.child}>
                                    {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                                  </Option>
                                );
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Địa chỉ */}
                  <Row style={{ marginBottom: '25px' }}>
                    <Col span={20}>
                      <Row>
                        <Col span={7}>{translate('project.location_project')}</Col>
                        <Col span={17}>
                          <Select
                            showSearch
                            value={valueMap}
                            placeholder="Tìm kiếm địa chỉ theo tên hoặc toạ độ (cú pháp tìm kiếm theo toạ độ:@21.02779326683,105.85227908255 )"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearchMap}
                            onChange={handleChangeMap}
                            notFoundContent={null}
                            style={{ width: '100%' }}
                          >
                            {data &&
                              data.length > 0 &&
                              data.map((d: any, index: number) => {
                                return (
                                  <Option key={index + 1} value={d.value} data={d}>
                                    {d.text}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="areaResearch"
                        label={translate('project.areaResearch')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.areaResearch'),
                            }),
                          },
                          // {
                          //   pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                          //   message: translate('messages.integer_field', {
                          //     label: translate('project.areaResearch'),
                          //   }),
                          // },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="areaConstruct"
                        label={translate('project.areaConstruct')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.areaConstruct'),
                            }),
                          },
                          // {
                          //   pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                          //   message: translate('messages.integer_field', {
                          //     label: translate('project.areaConstruct'),
                          //   }),
                          // },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="density"
                        label={`${translate('project.density')} (%)`}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.density'),
                            }),
                          },
                          {
                            pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                            message: translate('messages.integer_field', {
                              label: translate('project.density'),
                            }),
                          },
                        ]}
                      >
                        <InputNumber style={{ width: '100%' }} step={1} min={0} max={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Chủ đầu tư */}
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="investor"
                        label={translate('project.investor')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.investor')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'investor')}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.INVESTOR)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="developer"
                        label={translate('project.developer')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.developer')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'developer')}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.DEVELOPER)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data?.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="design"
                        label={translate('project.design')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.design')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'design')}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.DESIGN)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data?.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="contractor"
                        label={translate('project.contractor')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.contractor')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'contractor')}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.CONTRACTOR)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data?.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="marketing"
                        label={translate('project.marketing')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.marketing')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'marketing')}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.MARKETING)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data?.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="operator"
                        label={translate('project.operator')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.operator')}
                          mode="tags"
                          maxTagTextLength={50}
                          onChange={(value: any, option: any) => onChangeInvestor(value, option, 'operator')}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listConstructor && listConstructor.data
                            ? listConstructor.data.map((data: any, key: number) => {
                                if (data.type.includes(ConstructorType.OPERATOR)) {
                                  return (
                                    <Option value={data._id} key={data._id} title={data?.name}>
                                      {data.name}
                                    </Option>
                                  );
                                }
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="banks"
                        label={translate('project.banks')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={translate('project.banks')}
                          mode="multiple"
                          maxTagTextLength={50}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listBank && listBank.data
                            ? listBank.data.map((data: any, key: number) => (
                                <Option value={data._id} key={data._id} title={data?.name}>
                                  {data.name}
                                </Option>
                              ))
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="launchDate"
                        label={translate('project.launchDate')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.launchDate'),
                            }),
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder={translate('project.launchDate')}
                          style={{ width: '100%' }}
                          format={dateFormatBirthday}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={20} className="borderBottom"></Col>
                  </Row>
                  <Row>
                    <Col
                      span={20}
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        fontSize: '23px',
                        fontWeight: 'bold',
                      }}
                    >
                      {translate('project.product')}
                    </Col>
                  </Row>

                  {/* Chung cư */}
                  <Row>
                    <Col span={20}>
                      <Row>
                        <Col
                          span={7}
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginTop: '-25px',
                          }}
                        >
                          {translate('project.apartments')}
                          {/* <div className="quotaRightThree">{'{'}</div> */}
                        </Col>
                        <Col span={13}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="blocks"
                                label={translate('project.blocks')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.blocks'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="floors"
                                label={translate('project.floors')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.floors'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="apartments"
                                label={translate('project.sum_apartments')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.sum_apartments'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* Phí giữ chỗ */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="reservationFee"
                                label={'Phí đặt chỗ'}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                                  step={1000}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Phí đặt cọc */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="depositFee"
                                label="Phí đặt cọc"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Hoa hồng */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="commission"
                                label="Hoa hồng"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  {/* Biệt thự , Villa */}
                  <Row>
                    <Col span={20}>
                      <Row>
                        <Col
                          span={7}
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginTop: '-25px',
                          }}
                        >
                          {translate('project.villa')}
                          {/* <div className="quotaRightThree">{'{'}</div> */}
                        </Col>
                        <Col span={13}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="divisions"
                                label={translate('project.divisions')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.divisions'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="houses"
                                label={translate('project.houses')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.houses'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="floors_h"
                                label={translate('project.floors_h')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.floors_h'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* Phí giữ chỗ */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="reservationFee_1"
                                label={'Phí đặt chỗ'}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                                  step={1000}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Phí đặt cọc */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="depositFee_1"
                                label="Phí đặt cọc"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Hoa hồng */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="commission_1"
                                label="Hoa hồng"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  {/* Nhà phố,shophouse */}
                  <Row>
                    <Col span={20}>
                      <Row>
                        <Col
                          span={7}
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginTop: '-25px',
                          }}
                        >
                          {translate('project.shophouse')}
                          {/* <div className="quotaRightThree">{'{'}</div> */}
                        </Col>
                        <Col span={13}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="shophouse_divisions"
                                label={translate('project.shophouse_divisions')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.shophouse_divisions'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="shophouse_houses"
                                label={translate('project.shophouse_houses')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.shophouse_houses'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="shophouse_floors"
                                label={translate('project.shophouse_floors')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.shophouse_floors'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* Phí giữ chỗ */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="reservationFee_2"
                                label={'Phí đặt chỗ'}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                                  step={1000}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Phí đặt cọc */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="depositFee_2"
                                label="Phí đặt cọc"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Hoa hồng */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="commission_2"
                                label="Hoa hồng"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  {/* Đất nền */}
                  <Row>
                    <Col span={20}>
                      <Row>
                        <Col
                          span={7}
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                          {translate('project.land')}
                          {/* <div className="quotaRight">{'{'}</div> */}
                        </Col>
                        <Col span={13}>
                          <Row style={{ height: '70px' }}>
                            <Col span={24}>
                              <Form.Item
                                name="land_divisions"
                                label={translate('project.land_divisions')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.land_divisions'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ height: '70px' }}>
                            <Col span={24}>
                              <Form.Item
                                name="lots"
                                label={translate('project.lots')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^\d+$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.lots'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ height: '70px' }}>
                            <Col span={24}>
                              <Form.Item
                                name="minArea"
                                label={translate('project.minArea')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.minArea'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ height: '70px' }}>
                            <Col span={24}>
                              <Form.Item
                                name="maxArea"
                                label={translate('project.maxArea')}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                rules={[
                                  {
                                    pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                                    message: translate('messages.integer_field', {
                                      label: translate('project.maxArea'),
                                    }),
                                  },
                                ]}
                              >
                                <Input defaultValue={0} />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* Phí giữ chỗ */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="reservationFee_3"
                                label={'Phí đặt chỗ'}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                                  step={1000}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Phí đặt cọc */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="depositFee_3"
                                label="Phí đặt cọc"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* Hoa hồng */}
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="commission_3"
                                label="Hoa hồng"
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={100}
                                  formatter={(value: any) => `${value}%`}
                                  parser={(value: any) => value.replace('%', '')}
                                  step={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={20} className="borderBottom"></Col>
                  </Row>
                  <Row>
                    <Col
                      span={20}
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        fontSize: '23px',
                        fontWeight: 'bold',
                      }}
                    >
                      {translate('project.detail')}
                    </Col>
                  </Row>

                  {/* Quy mô dự án */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.scale')}</Col>
                      <Col span={17}>
                        <Form.Item name="scale">
                          <CKEEditor onChange={handleChangeContentScale} data={scaleContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_scale"
                        label={translate('project.image_scale')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListScale}
                          type={TYPE_DELETE_PROJECT.fileListScale}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeScale}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Tổng quan dự án */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.overview')}</Col>
                      <Col span={17}>
                        <Form.Item name="overview">
                          <CKEEditor onChange={handleContentOverview} data={overviewContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_overview"
                        label={translate('project.image_overview')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListOverview}
                          type={TYPE_DELETE_PROJECT.fileListOverview}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeOverview}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Tiện tích */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.utility')}</Col>
                      <Col span={17}>
                        <Form.Item name="utility">
                          <CKEEditor onChange={handleContentutility} data={utilityContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_utility"
                        label={translate('project.image_utility')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListUtility}
                          type={TYPE_DELETE_PROJECT.fileListUtility}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeUtility}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* mặt bằng tầng */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.ground_floor')}</Col>
                      <Col span={17}>
                        <Form.Item name="floorPlan">
                          <CKEEditor onChange={handleContentfloorPlan} data={floorPlanContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_ground_floor"
                        label={translate('project.image_ground_floor')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListfloorPlan}
                          type={TYPE_DELETE_PROJECT.fileListfloorPlan}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeFloorPlan}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Phương thức thanh toán */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.method_pay')}</Col>
                      <Col span={17}>
                        <Form.Item name="paymentMethod">
                          <CKEEditor onChange={handleContentPaymentMethod} data={payMentMethodContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_method_pay"
                        label={translate('project.image_method_pay')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListpaymentMethod}
                          type={TYPE_DELETE_PROJECT.fileListpaymentMethod}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeMethodPay}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Chính sách hỗ trợ bán hàng */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.policySale')}</Col>
                      <Col span={17}>
                        <Form.Item name="policySale">
                          <CKEEditor onChange={handleContentPolicySale} data={policySaleContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_policySale"
                        label={translate('project.image_policySale')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListPolicySale}
                          type={TYPE_DELETE_PROJECT.fileListPolicySale}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangePolicySale}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Chính sách ngân hàng hỗ trợ  */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.policyBank')}</Col>
                      <Col span={17}>
                        <Form.Item name="policyBank">
                          <CKEEditor onChange={handleContentPolicyBank} data={policyBankContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_policyBank"
                        label={translate('project.image_policyBank')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListPolicyBank}
                          type={TYPE_DELETE_PROJECT.fileListPolicyBank}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangePolicyBank}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Vị trí dự án */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>{translate('project.location_detail')}</Col>
                      <Col span={17}>
                        <Form.Item name="location_detail">
                          <CKEEditor onChange={handleContentLocationDetail} data={locationContent} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="image_location_detail"
                        label={translate('project.image_location_detail')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListLocationDetail}
                          type={TYPE_DELETE_PROJECT.fileListLocationDetail}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemAllProject}
                          onChange={handleChangeLocationDetail}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Thông tin liên hệ */}
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="contacts"
                        label={'Thông tin liên hệ của Phúc An Gia'}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <Select
                          placeholder={'Thông tin liên hệ của Phúc An Gia'}
                          mode="multiple"
                          maxTagTextLength={50}
                          onChange={onHandChangeContact}
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listAdmin && listAdmin.data
                            ? listAdmin.data.map((data: any, key: number) => {
                                return (
                                  <Option value={data._id} key={data._id} title={data?.name}>
                                    {data.name}
                                  </Option>
                                );
                              })
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Table thông tin liên hệ */}
                  {dataContact && dataContact.length > 0 && (
                    <Row>
                      <Col span={10} offset={6}>
                        <Table dataSource={dataContact} columns={columnsContact} pagination={false} />
                      </Col>
                    </Row>
                  )}

                  {/* Người phụ trách */}
                  <InChargeProject checkInCharge={isEdit} idInCharge={idInCharge} />
                  <Row>
                    <Col span={20} className="borderBottom"></Col>
                  </Row>
                  {/* Tài liệu đính kèm */}
                  <Row>
                    <Col
                      span={20}
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        fontSize: '23px',
                        fontWeight: 'bold',
                      }}
                    >
                      Tài liệu đính kèm
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="projectMediaIds"
                        label={translate('project.projectMediaIds')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListProjectMediaIds}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemProject}
                          onChange={handleChangeProjectMediaIds}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="locationMediaIds"
                        label={translate('project.locationMediaIds')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListLocationMediaIds}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemLocation}
                          onChange={handleChangeLocationMediaIds}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="overviewMediaIds"
                        label={translate('project.overviewMediaIds')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButtonImages
                          multiple
                          files={fileListOverviewMediaIds}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemOverView}
                          onChange={handleChangeOverviewMediaIds}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item name="areaMediaIds" label={'Ảnh quy mô'} labelCol={{ span: 7 }} labelAlign="left">
                        <UploadButtonImages
                          multiple
                          files={fileListOrtherMediaIds}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemOrther}
                          onChange={handleChangeOrtherMediaIds}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Ảnh khác */}
                  <Row>
                    <Col span={20}>
                      <Form.Item name="otherPhotos" label={'Ảnh khác'} labelCol={{ span: 7 }} labelAlign="left">
                        <UploadButtonImages
                          multiple
                          files={fileListAreaMediaIds}
                          accept={'.gif, .jpg, .png,'}
                          onPreview={handlePreview}
                          onRemove={onRemoveItemArea}
                          onChange={handleChangeAreaMediaIds}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Upload video */}
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="videos"
                        label={translate('project.videoIds')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <InputAddLinkVideo
                          files={fileListVideoIds}
                          onRemove={onRemoveItem}
                          onChange={onChangeInputFileVideo}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="files"
                        label={translate('project.files')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                      >
                        <UploadButton
                          multiple
                          accept={'.docx, .pdf, .xlsx, .xls , .doc'}
                          files={fileListFiles}
                          onRemove={onRemoveItemFile}
                          onChange={handleChangeFiles}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Cấu hình khác */}
                  <Row>
                    <Col
                      span={20}
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        fontSize: '23px',
                        fontWeight: 'bold',
                      }}
                    >
                      Cấu hình khác
                    </Col>
                  </Row>

                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="maxHoldTime"
                        label={translate('project.maxHoldTime')}
                        labelCol={{ span: 12 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.maxHoldTime'),
                            }),
                          },
                          {
                            pattern: /^\d+$/,
                            message: translate('messages.integer_field', {
                              label: translate('project.maxHoldTime'),
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="maxVolunteersSupportTime"
                        label={translate('project.maxVolunteersSupportTime')}
                        labelCol={{ span: 12 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('project.maxVolunteersSupportTime'),
                            }),
                          },
                          {
                            pattern: /^\d+$/,
                            message: translate('messages.integer_field', {
                              label: translate('project.maxVolunteersSupportTime'),
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Chi tiết căn hộ mẫu + lô đất */}
                  {isEdit && detailProject && (
                    <Row>
                      <Col>
                        <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Col>
                            <Link
                              href={`${ROUTER_PATH.PROJECTS_HOUSETEMPLATE}?idProject=${params.id}`}
                              target="_blank"
                              style={{
                                backgroundColor: '#5491da',
                                display: 'inline-block',
                                marginBottom: '20px',
                                padding: '15px 25px',
                                color: '#ffffff',
                                fontWeight: 'bold',
                              }}
                            >
                              Thông tin căn hộ mẫu
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ marginLeft: '10px' }}>
                        <Row gutter={5}>
                          <Col>
                            <Link
                              href={`${ROUTER_PATH.BLOCKS_AREA}?name=${detailProject.name}`}
                              target="_blank"
                              style={{
                                backgroundColor: '#5491da',
                                display: 'inline-block',
                                marginBottom: '20px',
                                padding: '15px 25px',
                                color: '#ffffff',
                                fontWeight: 'bold',
                              }}
                            >
                              Thông tin block, khu đất
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={10}>
                    <Col span={20}>
                      <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Col>
                          <Form.Item>
                            <Button type="default" onClick={closeButton}>
                              {translate('action.close')}
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" onClick={handSubmit}>
                              {translate('action.save')}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Skeleton>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormProjectPage;
