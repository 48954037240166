/* eslint-disable react/self-closing-comp */
/* eslint-disable no-script-url */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Radio, Pagination, Row, Table, DatePicker, Select, Modal, Typography, Tag } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumberPrice } from 'helpers/FormatPrice';
import React, { useEffect, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { setTimeout } from 'timers';
import useLitsProject from 'hooks/Project/useLitsProject';
import moment from 'moment';
import useAnalysisService from '../analysis.service';

const { Text, Link } = Typography;
const { Column } = Table;

enum QueryPAGProfitTimeType {
  MONTH_YEAR = 'MONTH_YEAR',
  QUARTER_YEAR = 'QUARTER_YEAR',
  YEAR = 'YEAR',
}
export interface PagProfitQuery {
  page: number | string;
  limit: number | string;
  timeType?: QueryPAGProfitTimeType;
  time?: string;
  exportExcel?: boolean;
  projectId?: string;
}

function ProfitComponent(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [pageDetailPag, setDetailPagPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [timeType, setTimeType] = useState<QueryPAGProfitTimeType>();
  const [disableDatePicker, setDisableDatePicker] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<PagProfitQuery>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    time: undefined,
    timeType: undefined,
    exportExcel: undefined,
    projectId: undefined,
  });

  const [selectedTime, setSelectedTime] = useState<string>();
  const [totalMonthProfit, setTotalMonthProfit] = useState<number>(0);
  const [filterDetail, setFilterDetail] = useState<PagProfitQuery>({
    page: pageDetailPag,
    limit: PaginateList.LIMIT_DEFAULT,
    time: undefined,
    timeType: QueryPAGProfitTimeType.MONTH_YEAR,
    exportExcel: undefined,
  });

  const {
    getProfit,
    dataProfit,
    totalProfit,
    loadingProfit,
    summaryProfit,
    onGetDetailPagProfit,
    detailPagProfit,
    totalDetailPagProfit,
  } = useAnalysisService();

  useEffect(() => {
    getProfit(filter);
  }, [filter]);

  const handleViewDetail = (year: string, month: string) => {
    setSelectedTime(`${year}-${month}`);
    setOpen(true);
    onGetDetailPagProfit({ ...filterDetail, time: `${year}-${month}` });
  };

  const columns = [
    {
      title: 'STT',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Năm',
      dataIndex: 'year',
      render: (year: string) => {
        return <span>{year}</span>;
      },
    },
    {
      title: 'Tháng',
      dataIndex: 'month',
      render: (month: string) => {
        return <span>{month}</span>;
      },
    },
    {
      title: 'Dự án',
      dataIndex: 'fromProject',
      render: (fromProject: number) => {
        return <span>{formatNumberPrice(fromProject)}</span>;
      },
    },
    {
      title: 'BĐS lẻ',
      dataIndex: 'fromProjectNews',
      render: (fromProjectNews: number) => {
        return <span>{formatNumberPrice(fromProjectNews)}</span>;
      },
    },
    {
      title: 'Nạp điểm',
      dataIndex: 'fromPointPackage',
      render: (fromPointPackage: number) => {
        return <span>{formatNumberPrice(fromPointPackage)}</span>;
      },
    },
    {
      title: 'Chi tiết',
      key: 'receivedDate',
      render: (item: any) => {
        return (
          <Button type="primary" onClick={() => handleViewDetail(item.year, item.month)}>
            <EyeOutlined />
          </Button>
        );
      },
      align: 'center' as any,
    },
  ];

  const onPageChange = (perPage: number) => {
    setPage(perPage);
    setFilter({ ...filter, page: perPage });
  };

  const onClickExcel = () => {
    setFilter({ ...filter, exportExcel: true });
    setTimeout(() => {
      setFilter({ ...filter, exportExcel: undefined });
    }, 1000);
  };

  const onClickDownLoadDetailToExcel = () => {
    onGetDetailPagProfit({ ...filterDetail, time: selectedTime, exportExcel: true });
    setOpen(false);
  };

  const summaryProfits = [
    {
      key: '1',
      profitFromProject: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromProject)} đồng`,
      profitFromProjectNews: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromProjectNews)} đồng`,
      profitFromPointPackage: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromPointPackage)} đồng`,
      totalProfit: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.totalProfit)} đồng`,
    },
  ];

  const options = [
    { label: 'Theo tháng', value: QueryPAGProfitTimeType.MONTH_YEAR },
    { label: 'Theo quý', value: QueryPAGProfitTimeType.QUARTER_YEAR },
    { label: 'Theo năm', value: QueryPAGProfitTimeType.YEAR },
  ];

  const onChangeTimeType = ({ target: { value } }: any) => {
    setTimeType(value);
    setDisableDatePicker(false);
    setFilter({ ...filter, timeType: value });
  };

  const onChangeDate: any['onChange'] = (date: any, dateString: string) => {
    setFilter({ ...filter, time: dateString });
  };

  const { onListProject, listProject } = useLitsProject();

  useEffect(() => {
    onListProject({});
  }, []);

  const handleChangeProject = (projectId: string) => {
    setFilter({ ...filter, projectId });
  };

  const columnsDetailPagProfit = [
    {
      title: 'STT',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Mã GD',
      dataIndex: 'code',
      render: (code: string) => {
        return <span>{code}</span>;
      },
    },
    {
      title: 'Loại Doanh Thu',
      dataIndex: 'type',
      render: (type: string) => {
        let label = '';
        switch (type) {
          case 'PAG_COMMISSION_FROM_PROJECT':
            label = 'Dự án';
            break;
          case 'COMMISSION_RETAIL_REAL_ESTATE_PAG':
            label = 'BĐS lẻ';
            break;
          case 'POINT_PACKAGE':
            label = 'Nạp gói điểm';
            break;
          default:
            break;
        }
        return <span>{label}</span>;
      },
    },
    {
      title: 'Sản phẩm',
      render: (item: any) => {
        let label = '';
        let link = '#';
        switch (item.type) {
          case 'PAG_COMMISSION_FROM_PROJECT':
            label = item.transactionInfo?.projectInfo?.name;
            link = `/project-manager/customer_project/${item.transactionId}`;
            break;
          case 'COMMISSION_RETAIL_REAL_ESTATE_PAG':
            label = item.transactionProjectNewsInfo?.projectNewsInfo?.landName;
            link = `/real-estate-manager/detail-related/${item.transactionId}`;
            break;
          case 'POINT_PACKAGE':
            label = `Gói điểm ${formatNumberPrice(item.money)}`;
            break;
          default:
            break;
        }
        return (
          <>
            {item.type === 'POINT_PACKAGE' ? (
              <Text underline style={{ color: '#12a0c0' }}>
                {label}
              </Text>
            ) : (
              <Link href={link} target="_blank">
                <Text underline style={{ color: '#12a0c0' }}>
                  {label}
                </Text>
              </Link>
            )}
          </>
        );
      },
    },
    {
      title: 'Số tiền',
      dataIndex: 'money',
      render: (money: number) => {
        return <Tag>{formatNumberPrice(Math.round(money))}</Tag>;
      },
    },
    {
      title: 'Thời gian',
      dataIndex: 'receivedDate',
      render: (receivedDate: string) => {
        return <span>{moment(receivedDate).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
  ];

  const onPageDetailPagChange = (p: number) => {
    setDetailPagPage(p);
    onGetDetailPagProfit({ ...filterDetail, time: selectedTime, page: p });
  };

  useEffect(() => {
    if (detailPagProfit && detailPagProfit.length) {
      const amount = detailPagProfit.reduce((prev, curr) => prev + curr.money, 0);
      setTotalMonthProfit(amount);
    }
  }, [detailPagProfit]);

  return (
    <>
      <Row style={{ marginBottom: '10px' }}>
        <Col span={20} style={{ fontSize: '20px' }}>
          <b>Lợi nhuận của Phúc An Gia </b>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={onClickExcel}>
            Xuất file excel
          </Button>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: '7px' }}>
        <Col span={24}>
          <Radio.Group options={options} onChange={onChangeTimeType} value={timeType} optionType="button" />
          <DatePicker
            disabled={disableDatePicker}
            style={{ marginLeft: '10px', width: '250px' }}
            onChange={onChangeDate}
            placeholder={`Vui lòng chọn ${
              timeType === QueryPAGProfitTimeType.MONTH_YEAR
                ? 'tháng'
                : timeType === QueryPAGProfitTimeType.YEAR
                ? 'năm'
                : timeType === QueryPAGProfitTimeType.QUARTER_YEAR
                ? 'quý'
                : 'thời gian'
            }`}
            picker={
              timeType === QueryPAGProfitTimeType.MONTH_YEAR
                ? 'month'
                : timeType === QueryPAGProfitTimeType.YEAR
                ? 'year'
                : 'quarter'
            }
          />
          {listProject && listProject.data.length > 0 && (
            <Select
              onChange={handleChangeProject}
              style={{ width: '300px', marginLeft: '10px' }}
              placeholder="Chọn dự án"
              allowClear
            >
              {listProject.data.map((e: any) => {
                return <Select.Option value={e._id}>{e.name}</Select.Option>;
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        {loadingProfit ? (
          <PageLoading />
        ) : (
          [
            <Col span={24}>
              <Table
                key={1}
                dataSource={dataProfit || []}
                columns={columns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
                bordered
              />
            </Col>,
            <Col span={24}>
              <Table dataSource={summaryProfits} bordered pagination={false}>
                <Column title="Tồng từ dự án" dataIndex="profitFromProject" key="profitFromProject" />
                <Column title="Tổng từ BĐS lẻ" dataIndex="profitFromProjectNews" key="profitFromProjectNews" />
                <Column title="Tổng từ nạp điểm" dataIndex="profitFromPointPackage" key="profitFromPointPackage" />
                <Column title="Tổng doanh thu" dataIndex="totalProfit" key="totalProfit" />
              </Table>
            </Col>,
          ]
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalProfit}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
        {selectedTime && (
          <Modal
            title={`Doanh thu của Phúc An Gia ${selectedTime} - Tổng trên trang: ${formatNumberPrice(
              Math.round(totalMonthProfit),
            )}`}
            centered
            visible={open}
            footer={false}
            onCancel={() => {
              setOpen(false);
              setDetailPagPage(PaginateList.PAGE_DEFAULT);
              onGetDetailPagProfit({
                page: pageDetailPag,
                limit: PaginateList.LIMIT_DEFAULT,
                time: undefined,
                timeType: QueryPAGProfitTimeType.MONTH_YEAR,
                exportExcel: undefined,
              });
            }}
            width={1200}
          >
            <Row style={{ marginBottom: '15px' }}>
              <Col span={20}></Col>
              <Col span={4}>
                <Button type="primary" onClick={onClickDownLoadDetailToExcel}>
                  Xuất file excel
                </Button>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  key={1}
                  dataSource={detailPagProfit || []}
                  columns={columnsDetailPagProfit}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                  rowKey="_id"
                  bordered
                />
              </Col>
              <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <div className="d-flex justify-content-end pagination">
                  <Pagination
                    showSizeChanger={false}
                    total={totalDetailPagProfit}
                    current={pageDetailPag}
                    defaultCurrent={pageDetailPag}
                    defaultPageSize={PaginateList.LIMIT_DEFAULT}
                    onChange={onPageDetailPagChange}
                  />
                </div>
              </Col>
            </Row>
          </Modal>
        )}
      </Row>
    </>
  );
}

export default ProfitComponent;
