import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { UpdateCtvRes, UpdateCtvInput } from '../types/ctv.types';

const apiName = {
  UPDATE_CTV: '/collaborator/update',
  QUICK_APPROVE: '/collaborator/approve-collab-request',
};

export const updateCtv = async (
  _id: string,
  body: UpdateCtvInput,
): Promise<{ status: boolean; messageKey: string }> => {
  const res = await ApiHelper.put<UpdateCtvInput, UpdateCtvRes>(`${apiName.UPDATE_CTV}/${_id}`, body);
  let messageKey = 'ctv.update_falure';
  let status = false;
  if (!res) messageKey = 'ctv.update_falure';
  switch (res.status) {
    case StatusResponse.SUCCESS:
      messageKey = 'ctv.update_success';
      status = true;
      break;
    case StatusResponse.COLLABORATOR_IS_NOT_INTRODUCER:
      messageKey = 'ctv.is_not_introducer';
      break;

    default:
      break;
  }
  if (res.status === StatusResponse.SUCCESS) {
    status = true;
  }
  return { status, messageKey };
};

export const quickApproveCollabRequest = async (id: string) => {
  const res = await ApiHelper.put<any, any>(`${apiName.QUICK_APPROVE}/${id}`);
  return res;
};
