/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface Props {
  onSearch: (data?: string) => void;
  onSearchDate: (data: string) => void;
}

function FilterNotificationComponent(props: Props): JSX.Element {
  const { onSearch, onSearchDate } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value ? event.target.value : undefined);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item name="search">
              <Input size="small" placeholder="Tên/SĐT" onChange={handleSearch} suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterNotificationComponent;
