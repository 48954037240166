/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { getUrlExport } from 'helpers/getImageUrl';
import { StatusResponse } from '../../constants/Status';
import { HistoryPointRes, HistoryReq } from './history-point.type';

const HistoryPointUrl = '/user/cms-histories-point';
const ExportHistoryPointToExcelUrl = '/user/cms-export-histories-point-to-excel';

function useHistoryPointService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataHistory, setDataHistory] = useState<HistoryPointRes[]>([]);
  const [totalHistory, setTotalHistory] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();

  const onGetHistoryPoint = async (params: HistoryReq) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(HistoryPointUrl, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalHistory(res.total);
        setDataHistory(res.data);
        setTotalPrice(res.totalPrice);
        setLoading(false);
      }
    } catch (error) {
      setDataHistory([]);
      setLoading(false);
    }
  };

  const onExportHistoryBuyPointToExcel = async (params: any) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(ExportHistoryPointToExcelUrl, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        window.open(getUrlExport(res.data));
      }
      setLoading(false);
    } catch (error) {}
  };
  return { onGetHistoryPoint, dataHistory, totalHistory, totalPrice, loading, onExportHistoryBuyPointToExcel };
}

export default useHistoryPointService;
