/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable react-native/no-inline-styles */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumber } from 'helpers/Utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHistoryPointComponent from './components/filter-history-point.component';
import useHistoryPointService from './history-point.service';
import { HistoryReq, UserInfo } from './history-point.type';

const { Link, Text } = Typography;

const HistoryPointScreen = (): JSX.Element => {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<HistoryReq>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    pointPackageId: undefined,
    minDate: undefined,
    maxDate: undefined,
  });

  const {
    onGetHistoryPoint,
    dataHistory,
    totalHistory,
    totalPrice,
    loading,
    onExportHistoryBuyPointToExcel,
  } = useHistoryPointService();

  useEffect(() => {
    onGetHistoryPoint(filter);
  }, [filter]);

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên người mua',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return (
          <span>
            <>
              {userInfo ? (
                <Link href={`${ROUTER_PATH.EDIT_CUSTOMER}${userInfo._id}`} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {userInfo.name}
                  </Text>
                </Link>
              ) : (
                'Deleted User'
              )}
            </>
          </span>
        );
      },
    },
    {
      title: 'Thời gian mua',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return <span>{userInfo ? userInfo.phone : ''}</span>;
      },
    },
    {
      title: 'Số tiền thanh toán',
      dataIndex: 'price',
      render: (price: number) => {
        return <span>{formatNumber(price)}</span>;
      },
    },
    {
      title: 'Gói điểm',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: 'Số điểm nhận',
      dataIndex: 'point',
      render: (point: number) => {
        return <span>{formatNumber(point)}</span>;
      },
    },
    {
      title: 'Tổng điểm hiện có',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return <span>{userInfo ? formatNumber(userInfo.personalPoint) : 0}</span>;
      },
    },
  ];

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm theo tên, SĐT
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );

  // Gói điểm
  const onPoint = (status: string) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, pointPackageId: status });
  };

  // Tháng/Năm
  const onDateRanger = (dateRanger: [string, string]) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, minDate: dateRanger[0] || undefined, maxDate: dateRanger[1] || undefined });
  };

  const onClickExportToExcel = () => {
    return onExportHistoryBuyPointToExcel(filter);
  };

  return (
    <>
      <FilterHistoryPointComponent
        onSearch={(data: string) => run(data)}
        onPoint={onPoint}
        onDateRanger={onDateRanger}
        onClickExportToExcel={onClickExportToExcel}
      />
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
          Tổng giá trị thanh toán: <b style={{ fontSize: '24px' }}>{formatNumber(totalPrice || 0)} </b>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Row>
            {loading ? (
              <PageLoading />
            ) : (
              <Col span={24}>
                <Table
                  key={1}
                  dataSource={dataHistory || []}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                  rowKey="_id"
                />
              </Col>
            )}
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={totalHistory}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HistoryPointScreen;
