/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Typography, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import _ from 'lodash';
import moment from 'moment';
import useNotificationService from 'pages/Notification/notification.service';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterNotificationComponent from './components/Filter.component';
import { FilterNotification, Payload } from './notification.type';

function ListNotificationPage(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterNotification>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    dateReceiveNotification: undefined,
  });

  const { onGetNotification, data, totalData, loading } = useNotificationService();

  useEffect(() => {
    onGetNotification(filter);
  }, [filter]);

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      width: '5%',
    },
    {
      title: 'Họ và tên',
      render: (user: any) => {
        return (
          <Typography.Link target="_blank" href={`${ROUTER_PATH.EDIT_CUSTOMER}${user._id}`}>
            {user.name}
          </Typography.Link>
        );
      },
      width: '20%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone: string) => {
        return <span>{phone}</span>;
      },
      width: '10%',
    },
    {
      title: 'Chứng minh thư',
      dataIndex: 'identify',
      render: (identify: string) => {
        return <span>{identify}</span>;
      },
      width: '10%',
    },
    {
      title: 'Vòng đời mới',
      dataIndex: 'startNewLifeCycle',
      render: (startNewLifeCycle: string) => {
        return <span>{startNewLifeCycle}</span>;
      },
      width: '20%',
    },
    {
      title: 'Giao dịch đã thành công',
      dataIndex: 'infoCheckRuleOfUser',
      render: (infoCheckRuleOfUser: Payload) => {
        return <span>{infoCheckRuleOfUser && infoCheckRuleOfUser.curentTransactionSuccess}</span>;
      },
      width: '10%',
    },
    {
      title: 'Thành viên đã giới thiệu',
      dataIndex: 'infoCheckRuleOfUser',
      render: (infoCheckRuleOfUser: Payload) => {
        return <span>{infoCheckRuleOfUser && infoCheckRuleOfUser.curentUserIntroduce}</span>;
      },
      width: '10%',
    },
  ];

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm theo tên gói
  const { run } = useDebounceFn(
    (search: string) => {
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );

  // Thay đổi trạng thái
  const onSearchDate = (data: any) => {
    setFilter({ ...filter, dateReceiveNotification: data ? moment(data).format('DD/MM/YYYY') : undefined });
  };

  return (
    <>
      <FilterNotificationComponent onSearch={(data: string) => run(data)} onSearchDate={onSearchDate} />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table rowKey="_id" dataSource={data || []} columns={columns} pagination={false} scroll={{ x: 'auto' }} />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalData}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ListNotificationPage;
