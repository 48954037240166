/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, ConfigProvider, DatePicker, Form, Input, Row, Select } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import { StatusType } from 'constants/Status';
import 'moment/locale/vi';
import usePointService from 'pages/PointPage/point.service';
import React, { useEffect } from 'react';

export interface Props {
  onSearch: (data?: string) => void;
  onPoint: (data?: string | number) => void;
  onDateRanger: (data?: [string, string]) => void;
  onClickExportToExcel: () => void;
}

const { RangePicker } = DatePicker;

const FilterHistoryPointComponent = (props: Props): JSX.Element => {
  const { onSearch, onPoint, onDateRanger, onClickExportToExcel } = props;

  const [form] = Form.useForm();
  const { onGetPoint, dataPoint } = usePointService();

  useEffect(() => {
    onGetPoint({ status: StatusType.ACTIVE });
  }, []);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value || undefined);
  };

  // Gói điểm
  const onSelectPoint = (value: string | number) => {
    onPoint(value || undefined);
  };

  // Tháng/Năm
  const onChangeRangePicker = (dates: [any, any], dateStrings: [string, string]) => {
    onDateRanger(dateStrings || undefined);
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <Form form={form}>
          <Row gutter={24} className="marginBottom">
            <Col span={6}>
              <Form.Item name="search">
                <Input size="small" suffix={<SearchOutlined />} onChange={onChangeSearch} placeholder="Tên, SĐT" />
              </Form.Item>
            </Col>
            {/* Gói điểm */}
            <Col span={4}>
              <Select placeholder="Gói điểm" style={{ width: '100%' }} onSelect={onSelectPoint}>
                <Select.Option value="">Tất cả</Select.Option>
                {dataPoint &&
                  dataPoint.length > 0 &&
                  dataPoint.map((data: any) => {
                    return (
                      <Select.Option value={data._id}>
                        {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={6}>
              <RangePicker
                picker="month"
                style={{ width: '100%' }}
                placeholder={['Tháng/Năm', 'Tháng/Năm']}
                format="MM/YYYY"
                onChange={onChangeRangePicker}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={onClickExportToExcel}>
                Xuất file excel
              </Button>
            </Col>
          </Row>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default FilterHistoryPointComponent;
